<template>
  <div>
    <v-card elevation="2" class="pa-5 mt-5">
      <v-card-title>
        アドミンアカウントの設定
      </v-card-title>

      <v-card-text v-if="user">
        <v-alert v-if="alert" :type="status" :value="alert" dismissible text>
          {{ statusMessage }}
        </v-alert>
        <v-col md="8">
          <v-row>
            <v-col>
              <v-text-field
                v-model="user.last_name"
                ref="last_name"
                :rules="rules.last_name"
                label="姓"
                prepend-inner-icon="mdi-account"
                :error-messages="errorMessages.last_name"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="user.first_name"
                ref="first_name"
                :rules="rules.first_name"
                label="名"
                prepend-inner-icon="mdi-account"
                :error-messages="errorMessages.first_name"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-text-field
            v-model="user.email"
            ref="email"
            :rules="rules.email"
            :error-messages="emailExistError"
            @change="validateEmail"
            label="メールアドレス"
            prepend-inner-icon="mdi-email"
          ></v-text-field>

          <v-btn class="mt-4" @click="changePassword = !changePassword"
            >パスワード変更</v-btn
          >
          <div v-if="changePassword">
            <v-text-field
              v-model="old_password"
              ref="old_password"
              :rules="rules.old_password"
              label="現在のパスワード"
              prepend-inner-icon="mdi-lock"
              :type="'password'"
              :error-messages="errorMessages.old_password"
            ></v-text-field>

            <v-text-field
              v-model="password"
              ref="password"
              :rules="rules.password"
              label="新しいパスワード"
              prepend-inner-icon="mdi-lock"
              :type="'password'"
              autocomplete="new-password"
              :error-messages="errorMessages.password"
            ></v-text-field>

            <v-text-field
              v-model="password_confirmation"
              ref="password_confirmation"
              :rules="rules.password_confirmation"
              :error-messages="errorMessages.password_confirmation"
              label="パスワード確認"
              prepend-inner-icon="mdi-lock-open"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              @change="errorMessages = ''"
              hide-details="auto"
            ></v-text-field>
          </div>
        </v-col>
      </v-card-text>
      <v-divider class="mt-12"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small :to="{ name: 'Quote' }">
          キャンセル
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="submit"
          :loading="loading"
          :disabled="loading"
        >
          更新する
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import api from '@/services/api-methods'

export default {
  data() {
    return {
      alert: false,
      status: null,
      statusMessage: null,
      changePassword: false,
      user: null,
      showPassword: false,
      old_password: null,
      password: null,
      password_confirmation: null,
      rules: {
        first_name: [v => !!v || '名 は必須項目です'],
        last_name: [v => !!v || '姓 は必須項目です'],
        email: [
          v => !!v || 'E-mail は必須項目です',
          v =>
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            '正しいメールアドレスの形式で入力してください。'
        ],
        old_password: [v => !!v || '現在のパスワードが必須です。'],
        password: [
          v => !!v || '新しいパスワードが必須です。',
          v => (v && v.length >= 6) || 'パスワードの長さは6文字以上必要です。'
        ],
        password_confirmation: [
          v => !!v || 'パスワード確認が必須です。',
          v => (v && v.length >= 6) || 'パスワードの長さは6文字以上必要です。',
          v =>
            v === this.password ||
            '新パスワードを再入力パスワードが一致しません。'
        ]
      },
      emailExistError: null,
      errorMessages: '',
      loading: false
    }
  },

  computed: {
    form() {
      return {
        last_name: this.user.last_name,
        first_name: this.user.first_name,
        email: this.user.email,

        old_password: this.old_password,
        password: this.password,
        password_confirmation: this.password_confirmation
      }
    },

    formData() {
      var formData = {}
      Object.keys(this.form).forEach(key => {
        if (!(this.form[key] == null || this.form[key] === '')) {
          if (!this.changePassword) {
            if (
              !(
                key == 'old_password' ||
                key == 'password' ||
                key == 'password_confirmation'
              )
            ) {
              formData[key] = this.form[key]
            }
          } else {
            formData[key] = this.form[key]
          }
        }
      })
      return formData
    }
  },

  beforeMount() {
    this.getLoggedInUser()
  },

  methods: {
    getLoggedInUser() {
      this.loading = true
      api
        .get('user')
        .then(response => {
          this.loading = false
          this.user = response.data.data
          this.user.status = this.user.status == 1 ? true : false
          this.user.role = this.user.user_type
          if (this.user.role == 'subadmin') this.visible = true
        })
        .catch(e => {
          this.loading = false
          console.log(e)
        })
    },

    validateEmail(val) {
      var valid_email_format = this.$refs['email'].validate(true)
      if (valid_email_format) {
        api
          .get('email/check/' + val)
          .then(res => {
            if (res.data.status == 'error') {
              this.emailExistError = res.data.message
            } else {
              this.emailExistError = null
            }
          })
          .catch(e => {
            console.log(e)
          })
      }
    },

    submit() {
      let formHasErrors = false
      Object.keys(this.form).forEach(f => {
        let success = true
        if (
          this.changePassword ||
          !(
            f == 'old_password' ||
            f == 'password' ||
            f == 'password_confirmation'
          )
        ) {
          success = this.$refs[f].validate(true)
        }
        if (!success) {
          formHasErrors = true
        }
      })
      if (!formHasErrors) {
        this.updateUser()
      }
    },

    updateUser() {
      api
        .update('users/' + this.$store.getters.user.id, this.formData)
        .then(res => {
          this.status = res.data.status
          this.statusMessage = res.data.message
          this.loading = false
          this.alert = true

          let user = res.data.data
          let payload = {
            last_name: user.last_name,
            first_name: user.first_name,
            full_name: user.last_name + ' ' + user.first_name,
            email: user.email
          }
          this.$store.commit('UPDATE_USER', payload)

          if (this.changePassword) {
            this.resetPasswordFields()
          }
          setTimeout(() => {
            this.alert = false
          }, 5000)
        })
        .catch(e => {
          this.loading = false
          this.errorMessages = e
          //   this.errorMessages = e.data.errors
        })
    },

    resetPasswordFields() {
      this.changePassword = false
      this.$refs['old_password'].reset()
      this.$refs['password'].reset()
      this.$refs['password_confirmation'].reset()
    }
  }
}
</script>
